import {
  GoogleMap,
  LoadScript,
  Polygon,
  Marker,
  Polyline,
} from '@react-google-maps/api';
import DrinkingSourceIcon from '../../../assets/images/mapIcons/drinking-source.svg';
import NonDrinkingSourceIcon from '../../../assets/images/mapIcons/non-drinking-source.svg';
import VillagePondIcon from '../../../assets/images/mapIcons/village-pond.svg';
import FarmPondIcon from '../../../assets/images/mapIcons/farm-pond.svg';
import PercolationTankIcon from '../../../assets/images/mapIcons/percolation-tank.svg';
import CheckDamIcon from '../../../assets/images/mapIcons/check-dam.svg';
import WtpIcon from '../../../assets/images/mapIcons/water-treatment-plant.svg';
import EsrIcon from '../../../assets/images/mapIcons/elevated-storage-reservoir.svg';
import CommunityStandpostIcon from '../../../assets/images/mapIcons/community-standpost.svg';
import SchoolIcon from '../../../assets/images/mapIcons/school.svg';
import HospitalIcon from '../../../assets/images/mapIcons/hospital.svg';
import TempleIcon from '../../../assets/images/mapIcons/temple.svg';
import PipeDistributionNetworkIcon from '../../../assets/images/mapIcons/pipe-distribution-network.svg';
import GreyWaterInfrastructureIcon from '../../../assets/images/mapIcons/grey-water-infrastructure.svg';
import './index.module.css';
import { WellTypes } from '../../../shared/enums/well-type';
import WaterStructure from '../../../shared/enums/water-structure';
import { WATER_DISTRIBUTION_NETWORK_TYPES } from '../../../shared/enums/water-distribution-network-types';
import { INSTITUTION } from '../../../shared/enums/institution';
import {
  PA_ID,
  CD_ID,
  CS_ID,
  DW_ID,
  ESR_ID,
  FP_ID,
  GWI_ID,
  RA_ID,
  HO_ID,
  NDW_ID,
  PDN_ID,
  PT_ID,
  SC_ID,
  TE_ID,
  AA_ID,
  VP_ID,
  AMA_ID,
  WTP_ID,
} from '../../../shared/utils/tree';
import { GIS_LAND_USE } from '../../../shared/enums/gis-land-use';
import { landUseColorMapping } from '../Analytics/Source/PD';
import { VIEW_TYPE } from '../../../shared/enums/view-type';
import { CONTOUR, GEOLOGY, GEOMORPHOLOGY, PATTERN } from '../Views';
import VulnerableDrinkingSourceIcon from '../../../assets/images/mapIcons/vulnerable-drinking-source.svg';

const Map = ({
  villageBoundary,
  mapCenter,
  gis,
  selectedItems,
  handleSelectedPointChange,
  activeViews,
}) => {
  const API_KEY = 'AIzaSyCaaigbj0c7yOaM_k3EuaUY3zIOkcwrEq4';
  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  };

  const options = {
    fullscreenControl: false,
    zoomControl: false,
    mapTypeControl: false,
  };

  const gwSources = gis?.groundwater_source?.points;
  const drinkingGWSources = selectedItems[DW_ID]
    ? gwSources?.filter((gwS) => gwS.type === WellTypes.DRINKING)
    : [];
  const nonDrinkingGWSources = selectedItems[NDW_ID]
    ? gwSources?.filter((gwS) => gwS.type === WellTypes.NON_DRINKING)
    : [];

  const waterStructures = gis?.surfacewater_source?.points;
  const villagePonds = selectedItems[VP_ID]
    ? waterStructures?.filter(
        (ws) =>
          ws.type?.toLowerCase()?.trim() ===
          WaterStructure.VILLAGE_POND.toLowerCase()?.trim(),
      )
    : [];
  const farmPonds = selectedItems[FP_ID]
    ? waterStructures?.filter(
        (ws) =>
          ws.type?.toLowerCase()?.trim() ===
          WaterStructure.FARM_POND.toLowerCase()?.trim(),
      )
    : [];
  const percolationTanks = selectedItems[PT_ID]
    ? waterStructures?.filter(
        (ws) =>
          ws.type?.toLowerCase()?.trim() ===
          WaterStructure.PERCOLATION_TANK.toLowerCase()?.trim(),
      )
    : [];
  const checkDams = selectedItems[CD_ID]
    ? waterStructures?.filter(
        (ws) =>
          ws.type?.toLowerCase()?.trim() ===
          WaterStructure.CHECK_DAM.toLowerCase()?.trim(),
      )
    : [];

  const convertGeomToPaths = (geom) => {
    if (geom?.type === 'LineString') {
      // For LineString, map coordinates
      return geom?.coordinates?.map((coord) => ({
        lat: coord[1], // lat comes first
        lng: coord[0], // lng comes second
      }));
    } else if (geom?.type === 'MultiLineString') {
      // For MultiLineString, map each set of coordinates
      return geom?.coordinates?.map((line) =>
        line?.map((coord) => ({
          lat: coord[1], // lat comes first
          lng: coord[0], // lng comes second
        })),
      );
    } else {
      // For MultiPolygon, handle as before
      return geom?.coordinates?.map((polygon) =>
        polygon?.[0]?.map((coord) => ({
          lat: coord[1], // lat comes first
          lng: coord[0], // lng comes second
        })),
      );
    }
  };

  const calculateCentroid = (paths) => {
    let latSum = 0;
    let lngSum = 0;
    let numPoints = 0;

    if (Array.isArray(paths) && paths.length > 0) {
      paths.forEach((path) => {
        if (Array.isArray(path)) {
          path.forEach((point) => {
            latSum += point?.lat;
            lngSum += point?.lng;
            numPoints++;
          });
        } else {
          // Handle LineString case where `path` is not an array
          latSum += path?.lat;
          lngSum += path?.lng;
          numPoints++;
        }
      });
    }

    return numPoints > 0
      ? { lat: latSum / numPoints, lng: lngSum / numPoints }
      : null;
  };

  const wdns = gis?.water_distribution_network?.points;
  const wtps = selectedItems[WTP_ID]
    ? wdns?.filter(
        (wdn) =>
          wdn.type?.toLowerCase()?.trim() ===
          WATER_DISTRIBUTION_NETWORK_TYPES.WTP.toLowerCase()?.trim(),
      )
    : [];
  const esrs = selectedItems[ESR_ID]
    ? wdns?.filter(
        (wdn) =>
          wdn.type?.toLowerCase()?.trim() ===
          WATER_DISTRIBUTION_NETWORK_TYPES.ESR.toLowerCase()?.trim(),
      )
    : [];
  const pdns = selectedItems[PDN_ID]
    ? wdns?.filter(
        (wdn) =>
          wdn.type?.toLowerCase()?.trim() ===
          WATER_DISTRIBUTION_NETWORK_TYPES.PDN.toLowerCase()?.trim(),
      )
    : [];
  const gwis = selectedItems[GWI_ID]
    ? wdns?.filter(
        (wdn) =>
          wdn.type?.toLowerCase()?.trim() ===
          WATER_DISTRIBUTION_NETWORK_TYPES.GWI.toLowerCase()?.trim(),
      )
    : [];

  const pop = gis?.population_density?.points;
  const communityStandposts = selectedItems[CS_ID]
    ? pop?.filter(
        (i) =>
          i.type?.toLowerCase()?.trim() ===
          INSTITUTION.COMMUNITY_STANDPOST.toLowerCase()?.trim(),
      )
    : [];
  const hospitals = selectedItems[HO_ID]
    ? pop?.filter(
        (i) =>
          i.type?.toLowerCase()?.trim() ===
          INSTITUTION.HOSPITAL.toLowerCase()?.trim(),
      )
    : [];
  const schools = selectedItems[SC_ID]
    ? pop?.filter(
        (i) =>
          i.type?.toLowerCase()?.trim() ===
          INSTITUTION.SCHOOL.toLowerCase()?.trim(),
      )
    : [];
  const temples = selectedItems[TE_ID]
    ? pop?.filter(
        (i) =>
          i.type?.toLowerCase()?.trim() ===
          INSTITUTION.TEMPLE.toLowerCase()?.trim(),
      )
    : [];
  const agricultures = selectedItems[AA_ID]
    ? pop?.filter(
        (v) =>
          v.type?.toLowerCase()?.trim() ===
          GIS_LAND_USE.AGRICULTURE_AREA.toLowerCase()?.trim(),
      )
    : [];
  const residences = selectedItems[RA_ID]
    ? pop?.filter(
        (h) =>
          h.type?.toLowerCase()?.trim() ===
          GIS_LAND_USE.RESIDENTIAL_AREA.toLowerCase()?.trim(),
      )
    : [];
  const publics = selectedItems[PA_ID]
    ? pop?.filter(
        (bl) =>
          bl.type?.toLowerCase()?.trim() ===
          GIS_LAND_USE.PUBLIC_AREA.toLowerCase()?.trim(),
      )
    : [];
  const noMans = selectedItems[AMA_ID]
    ? pop?.filter(
        (w) =>
          w.type?.toLowerCase()?.trim() ===
          GIS_LAND_USE.NO_MAN_AREA.toLowerCase()?.trim(),
      )
    : [];

  return (
    <>
      <LoadScript googleMapsApiKey={API_KEY}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={{
            lat: mapCenter[0],
            lng: mapCenter[1],
          }}
          zoom={window.innerWidth < 768 ? 13 : 14}
          options={options}
        >
          {villageBoundary.length > 0 && (
            <Polygon
              paths={villageBoundary.map((coord) => ({
                lat: coord[0],
                lng: coord[1],
              }))}
              options={{
                fillColor: '#FFF',
                fillOpacity: 0,
                strokeColor: '#878787',
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          )}
          {activeViews.has(VIEW_TYPE.DRAINAGE_PATTERN) &&
            gis?.views?.drainage_pattern?.map((pattern) => {
              if (pattern.geom) {
                const paths = convertGeomToPaths(pattern.geom);
                if (!paths) {
                  return <></>;
                }

                // If paths is an array of arrays (for MultiLineString)
                if (Array.isArray(paths[0])) {
                  return paths.map((path, index) => (
                    <Polyline
                      key={`${pattern.id}-${index}`} // Unique key for each path
                      path={path}
                      options={{
                        strokeColor: PATTERN[0].strokeColor,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                  ));
                } else {
                  // Single LineString case
                  return (
                    <Polyline
                      key={pattern.id}
                      path={paths}
                      options={{
                        strokeColor: PATTERN[0].strokeColor,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                  );
                }
              } else return <></>;
            })}
          {activeViews.has(VIEW_TYPE.CONTOUR_N_SLOPE) &&
            gis?.views?.contour_slope?.map((pattern) => {
              if (pattern.geom) {
                const color = CONTOUR.find(
                  (geo) =>
                    geo.label?.toLowerCase()?.trim() ===
                    pattern.type?.toLowerCase()?.trim(),
                );
                const paths = convertGeomToPaths(pattern.geom);
                if (!paths) {
                  return <></>;
                }

                return (
                  <Polygon
                    key={pattern.id}
                    paths={paths}
                    options={{
                      fillColor: color.fillColor,
                      fillOpacity: 0.4,
                      strokeColor: color.strokeColor,
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                    }}
                  />
                );
              } else return <></>;
            })}
          {activeViews.has(VIEW_TYPE.GEOLOGY) &&
            gis?.views?.geology?.map((pattern) => {
              if (pattern.geom) {
                const paths = convertGeomToPaths(pattern.geom);
                if (!paths) {
                  return <></>;
                }

                return (
                  <Polygon
                    key={pattern.id}
                    paths={paths}
                    options={{
                      fillColor: GEOLOGY[0].fillColor,
                      fillOpacity: 0.4,
                      strokeColor: GEOLOGY[0].strokeColor,
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                    }}
                  />
                );
              } else return <></>;
            })}
          {activeViews.has(VIEW_TYPE.GEOMORPHOLOGY) &&
            gis?.views?.geomorphology?.map((pattern) => {
              if (pattern.geom) {
                const color = GEOMORPHOLOGY.find(
                  (geo) =>
                    geo.type?.toLowerCase()?.trim()?.trim() ===
                    pattern.type_of_geomorphology?.toLowerCase()?.trim(),
                );
                const paths = convertGeomToPaths(pattern.geom);
                if (!paths) {
                  return <></>;
                }
                if (!color) {
                  return <></>;
                }

                return (
                  <Polygon
                    key={pattern.id}
                    paths={paths}
                    options={{
                      fillColor: color.fillColor,
                      fillOpacity: 0.4,
                      strokeColor: color.strokeColor,
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                    }}
                  />
                );
              } else return <></>;
            })}
          {drinkingGWSources?.length > 0 &&
            drinkingGWSources.map((source) => (
              <Marker
                key={source.name}
                position={{ lat: source.lat, lng: source.lng }}
                icon={{
                  url: source.is_vulnerable
                    ? VulnerableDrinkingSourceIcon
                    : DrinkingSourceIcon,
                  scaledSize: window.google?.maps
                    ? new window.google.maps.Size(35, 35)
                    : null,
                }}
                onClick={() => handleSelectedPointChange(source)}
              />
            ))}
          {nonDrinkingGWSources?.length > 0 &&
            nonDrinkingGWSources.map((source) => (
              <Marker
                key={source.name}
                position={{ lat: source.lat, lng: source.lng }}
                icon={{
                  url: NonDrinkingSourceIcon,
                  scaledSize: window.google?.maps
                    ? new window.google.maps.Size(35, 35)
                    : null,
                }}
                onClick={() => handleSelectedPointChange(source)}
              />
            ))}

          {/* Village Ponds */}
          {villagePonds?.length > 0 &&
            villagePonds.map((structure, index) => {
              if (structure.geom) {
                const paths = convertGeomToPaths(structure.geom);
                if (!paths) {
                  return <></>;
                }
                const centroid = calculateCentroid(paths);
                if (!centroid) {
                  return <></>;
                }

                return (
                  <div key={index}>
                    <Polygon
                      paths={paths}
                      options={{
                        fillColor: '#1E90FF', // DodgerBlue for water
                        fillOpacity: 0.4,
                        strokeColor: '#1E90FF',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                    <Marker
                      position={centroid}
                      icon={{
                        url: VillagePondIcon,
                        scaledSize: window.google?.maps
                          ? new window.google.maps.Size(35, 35)
                          : null,
                      }}
                      onClick={() => handleSelectedPointChange(structure)}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}

          {/* Farm Ponds */}
          {farmPonds?.length > 0 &&
            farmPonds.map((structure, index) => {
              if (structure.geom) {
                const paths = convertGeomToPaths(structure.geom);
                if (!paths) {
                  return <></>;
                }
                const centroid = calculateCentroid(paths);
                if (!centroid) {
                  return <></>;
                }

                return (
                  <div key={index}>
                    <Polygon
                      paths={paths}
                      options={{
                        fillColor: '#1E90FF',
                        fillOpacity: 0.4,
                        strokeColor: '#1E90FF',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                    <Marker
                      position={centroid}
                      icon={{
                        url: FarmPondIcon,
                        scaledSize: window.google?.maps
                          ? new window.google.maps.Size(35, 35)
                          : null,
                      }}
                      onClick={() => handleSelectedPointChange(structure)}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}

          {/* Percolation Tanks */}
          {percolationTanks?.length > 0 &&
            percolationTanks.map((structure, index) => {
              if (structure.geom) {
                const paths = convertGeomToPaths(structure.geom);
                if (!paths) {
                  return <></>;
                }
                const centroid = calculateCentroid(paths);
                if (!centroid) {
                  return <></>;
                }

                return (
                  <div key={index}>
                    <Polygon
                      paths={paths}
                      options={{
                        fillColor: '#1E90FF',
                        fillOpacity: 0.4,
                        strokeColor: '#1E90FF',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                    <Marker
                      position={centroid}
                      icon={{
                        url: PercolationTankIcon,
                        scaledSize: window.google?.maps
                          ? new window.google.maps.Size(35, 35)
                          : null,
                      }}
                      onClick={() => handleSelectedPointChange(structure)}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}

          {/* Check Dams */}
          {checkDams?.length > 0 &&
            checkDams.map((structure, index) => {
              if (structure.geom) {
                const paths = convertGeomToPaths(structure.geom);
                if (!paths) {
                  return <></>;
                }
                const centroid = calculateCentroid(paths);
                if (!centroid) {
                  return <></>;
                }

                return (
                  <div key={index}>
                    <Polygon
                      paths={paths}
                      options={{
                        fillColor: '#1E90FF',
                        fillOpacity: 0.4,
                        strokeColor: '#1E90FF',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                    <Marker
                      position={centroid}
                      icon={{
                        url: CheckDamIcon,
                        scaledSize: window.google?.maps
                          ? new window.google.maps.Size(35, 35)
                          : null,
                      }}
                      onClick={() => handleSelectedPointChange(structure)}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}

          {wtps?.length > 0 &&
            wtps.map((source, index) => (
              <Marker
                key={index}
                position={{ lat: source.lat, lng: source.lng }}
                icon={{
                  url: WtpIcon,
                  scaledSize: window.google?.maps
                    ? new window.google.maps.Size(35, 35)
                    : null,
                }}
                onClick={() => handleSelectedPointChange(source)}
              />
            ))}

          {esrs?.length > 0 &&
            esrs.map((source, index) => (
              <Marker
                key={index}
                position={{ lat: source.lat, lng: source.lng }}
                icon={{
                  url: EsrIcon,
                  scaledSize: window.google?.maps
                    ? new window.google.maps.Size(35, 35)
                    : null,
                }}
                onClick={() => handleSelectedPointChange(source)}
              />
            ))}

          {pdns?.length > 0 &&
            pdns.map((structure, index) => {
              if (structure.geom) {
                const paths = convertGeomToPaths(structure.geom);
                if (!paths) {
                  return <></>;
                }
                const centroid = calculateCentroid(paths);
                if (!centroid) {
                  return <></>;
                }

                return (
                  <div key={index}>
                    <Polyline
                      path={paths} // Handle both Polygon and LineString
                      options={{
                        strokeColor: '#00008B',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                    <Marker
                      position={centroid}
                      icon={{
                        url: PipeDistributionNetworkIcon,
                        scaledSize: window.google?.maps
                          ? new window.google.maps.Size(35, 35)
                          : null,
                      }}
                      onClick={() => handleSelectedPointChange(structure)}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}

          {gwis?.length > 0 &&
            gwis.map((structure, index) => {
              if (structure.geom) {
                const paths = convertGeomToPaths(structure.geom);
                if (!paths) {
                  return <></>;
                }
                const centroid = calculateCentroid(paths);
                if (!centroid) {
                  return <></>;
                }

                return (
                  <div key={index}>
                    <Polyline
                      path={paths}
                      options={{
                        strokeColor: '#1E90FF',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                    <Marker
                      position={centroid}
                      icon={{
                        url: GreyWaterInfrastructureIcon,
                        scaledSize: window.google?.maps
                          ? new window.google.maps.Size(35, 35)
                          : null,
                      }}
                      onClick={() => handleSelectedPointChange(structure)}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}

          {communityStandposts?.length > 0 &&
            communityStandposts.map((source, index) => (
              <Marker
                key={index}
                position={{ lat: source.lat, lng: source.lng }}
                icon={{
                  url: CommunityStandpostIcon,
                  scaledSize: window.google?.maps
                    ? new window.google.maps.Size(35, 35)
                    : null,
                }}
                onClick={() => handleSelectedPointChange(source)}
              />
            ))}

          {schools?.length > 0 &&
            schools.map((source, index) => (
              <Marker
                key={index}
                position={{ lat: source.lat, lng: source.lng }}
                icon={{
                  url: SchoolIcon,
                  scaledSize: window.google?.maps
                    ? new window.google.maps.Size(35, 35)
                    : null,
                }}
                onClick={() => handleSelectedPointChange(source)}
              />
            ))}

          {hospitals?.length > 0 &&
            hospitals.map((source, index) => (
              <Marker
                key={index}
                position={{ lat: source.lat, lng: source.lng }}
                icon={{
                  url: HospitalIcon,
                  scaledSize: window.google?.maps
                    ? new window.google.maps.Size(35, 35)
                    : null,
                }}
                onClick={() => handleSelectedPointChange(source)}
              />
            ))}

          {temples?.length > 0 &&
            temples.map((source, index) => (
              <Marker
                key={index}
                position={{ lat: source.lat, lng: source.lng }}
                icon={{
                  url: TempleIcon,
                  scaledSize: window.google?.maps
                    ? new window.google.maps.Size(35, 35)
                    : null,
                }}
                onClick={() => handleSelectedPointChange(source)}
              />
            ))}

          {agricultures?.length > 0 &&
            agricultures.map((structure, index) => {
              if (structure.geom) {
                const paths = convertGeomToPaths(structure.geom);
                if (!paths) {
                  return <></>;
                }
                return (
                  <div key={index}>
                    <Polygon
                      paths={paths}
                      options={{
                        fillColor:
                          landUseColorMapping[GIS_LAND_USE.AGRICULTURE_AREA],
                        fillOpacity: 0.4,
                        strokeColor:
                          landUseColorMapping[GIS_LAND_USE.AGRICULTURE_AREA],
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}

          {residences?.length > 0 &&
            residences.map((structure, index) => {
              if (structure.geom) {
                const paths = convertGeomToPaths(structure.geom);
                if (!paths) {
                  return <></>;
                }
                return (
                  <div key={index}>
                    <Polygon
                      paths={paths}
                      options={{
                        fillColor:
                          landUseColorMapping[GIS_LAND_USE.RESIDENTIAL_AREA],
                        fillOpacity: 0.4,
                        strokeColor:
                          landUseColorMapping[GIS_LAND_USE.RESIDENTIAL_AREA],
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}

          {publics?.length > 0 &&
            publics.map((structure, index) => {
              if (structure.geom) {
                const paths = convertGeomToPaths(structure.geom);
                if (!paths) {
                  return <></>;
                }
                return (
                  <div key={index}>
                    <Polygon
                      paths={paths}
                      options={{
                        fillColor:
                          landUseColorMapping[GIS_LAND_USE.PUBLIC_AREA],
                        fillOpacity: 0.4,
                        strokeColor:
                          landUseColorMapping[GIS_LAND_USE.PUBLIC_AREA],
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}

          {noMans?.length > 0 &&
            noMans.map((structure, index) => {
              if (structure.geom) {
                const paths = convertGeomToPaths(structure.geom);
                if (!paths) {
                  return <></>;
                }
                return (
                  <div key={index}>
                    <Polygon
                      paths={paths}
                      options={{
                        fillColor:
                          landUseColorMapping[GIS_LAND_USE.NO_MAN_AREA],
                        fillOpacity: 0.4,
                        strokeColor:
                          landUseColorMapping[GIS_LAND_USE.NO_MAN_AREA],
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default Map;
