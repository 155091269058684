import styles from './index.module.css';
import ThumbnailImg from '../../../assets/images/layers-icons/views-icons/thumbnail.png';
import ThumbnailOverlayIcon from '../../../assets/images/layers-icons/views-icons/thumbnail-overlay.svg';
import DrainagePatternImg from '../../../assets/images/layers-icons/views-icons/drainage-pattern.png';
import ContourSlopeImg from '../../../assets/images/layers-icons/views-icons/contour-slope.png';
import GeologyImg from '../../../assets/images/layers-icons/views-icons/geology.png';
import GeomorphologyImg from '../../../assets/images/layers-icons/views-icons/geomorphology.png';
import { FaChevronRight } from 'react-icons/fa';
import { useState } from 'react';
import { VIEW_TYPE } from '../../../shared/enums/view-type';
import { FaCheck } from 'react-icons/fa';

export const GEOMORPHOLOGY = [
  {
    label: 'Abandoned Channel',
    type: 'Abandoned Channel',
    fillColor: '#006400', // Dark Green
    strokeColor: '#004b00', // A slightly darker green for stroke
  },
  {
    label: 'Cut-off Meander',
    type: 'Cut-off Meander',
    fillColor: '#800080', // Purple
    strokeColor: '#4b004b', // A darker purple for stroke
  },
  {
    label: 'Lateral Bar',
    type: 'Lateral Bar',
    fillColor: '#008000', // Green
    strokeColor: '#004b00', // Darker green for stroke
  },
  {
    label: 'WatBod - River',
    type: 'WatBod - River',
    fillColor: '#0000FF', // Blue
    strokeColor: '#00008b', // A darker blue for stroke
  },
  {
    label: 'WatBod - Pond',
    type: 'WatBod - Pond',
    fillColor: '#ADD8E6', // Light Blue
    strokeColor: '#6aa7c4', // A mid-tone blue for stroke
  },
  {
    label: 'Meander scar',
    type: 'Meander scar',
    fillColor: '#808000', // Olive
    strokeColor: '#4b4b00', // Dark olive for stroke
  },
  {
    label: 'Channel Bar',
    type: 'Channel Bar',
    fillColor: '#FFA500', // Orange
    strokeColor: '#cc8400', // A darker orange for stroke
  },
  {
    label: 'FluOri - Piedmont Alluvial Plain',
    type: 'FluOri - Piedmont Alluvial Plain',
    fillColor: '#FFFF00', // Yellow
    strokeColor: '#cccc00', // A darker yellow for stroke
  },
  {
    label: 'Older Alluvial Plain',
    type: 'Older Alluvial Plain',
    fillColor: '#90EE90', // Light Green
    strokeColor: '#6abf6a', // A mid-tone green for stroke
  },
  {
    label: 'FluOri - Older Flood plain',
    type: 'FluOri - Older Flood plain',
    fillColor: '#A52A2A', // Brown
    strokeColor: '#731f1f', // A darker brown for stroke
  },
  {
    label: 'FluOri - Active Flood plain',
    type: 'FluOri - Active Flood plain',
    fillColor: '#E0E0B0', // Light Yellow
    strokeColor: '#B0B08A', // A darker yellowish tint for stroke
  },
];

export const CONTOUR = [
  {
    label: '0_5',
    type: '0% to 5%',
    fillColor: '#008000', // Green
    strokeColor: '#004b00', // Darker green for stroke
  },
  {
    label: '5_10',
    type: '5% to 10%',
    fillColor: '#FFFF00', // Yellow
    strokeColor: '#cccc00', // Darker yellow for stroke
  },
  {
    label: '10_15',
    type: '10% to 15%',
    fillColor: '#FFA500', // Orange
    strokeColor: '#cc8400', // Darker orange for stroke
  },
  {
    label: '15_20',
    type: '15% to 20%',
    fillColor: '#FF0000', // Red
    strokeColor: '#b30000', // Darker red for stroke
  },
  {
    label: 'above_20',
    type: 'above 20%',
    fillColor: '#8B0000', // Dark Red
    strokeColor: '#4b0000', // A very dark red for stroke
  },
];

export const PATTERN = [
  {
    label: 'Drainage Lines',
    type: 'Drainage Lines',
    fillColor: '#ADD8E6', // Light Blue
    strokeColor: '#6aa7c4', // A mid-tone blue for stroke
  },
];

export const GEOLOGY = [
  {
    label: 'Undiff.Fluvial/Aeolian/Glacial/Coastal Sediments',
    type: 'Undiff.Fluvial/Aeolian/Glacial/Coastal Sediments',
    fillColor: '#86665a', // A light brown (Moccasin)
    strokeColor: '#5c4943', // A darker brown for stroke
  },
];

const Views = ({ activeViews, handleToggleView }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const checkOverlay = () => {
    return (
      <div className={styles.checkOverlay}>
        <FaCheck />
      </div>
    );
  };

  return (
    <div className={styles.views} onClick={() => setIsCollapsed(false)}>
      <div className={styles.container}>
        <div className={styles.thumbnailContainer}>
          <img
            className={styles.thumbnail}
            src={ThumbnailImg}
            alt="Thumbnail"
          />
          <div className={styles.thumbnailOverlay}>
            <img
              src={ThumbnailOverlayIcon}
              alt="overlay-icon"
              className={styles.thumbnailOverlayIcon}
            />
            <div className={styles.thumbnailOverlayText}>
              {activeViews.size > 0 ? `+${activeViews.size}` : 'VIEWS'}
            </div>
          </div>
        </div>
        <div
          className={styles.spacer}
          style={{ display: isCollapsed ? 'none' : undefined }}
        />
        <div
          className={styles.viewContainer}
          style={{ display: isCollapsed ? 'none' : undefined }}
          onClick={() => handleToggleView(VIEW_TYPE.DRAINAGE_PATTERN)}
        >
          <div
            className={`${styles.viewImageContainer} ${
              activeViews.has(VIEW_TYPE.DRAINAGE_PATTERN) ? styles.selected : ''
            }`}
          >
            <img className={styles.view} src={DrainagePatternImg} alt="View" />
            {activeViews.has(VIEW_TYPE.DRAINAGE_PATTERN) && checkOverlay()}
          </div>
          <div className={styles.viewTitle}>Drainage</div>
        </div>
        <div
          className={styles.spacer}
          style={{ display: isCollapsed ? 'none' : undefined }}
        />
        <div
          className={styles.viewContainer}
          style={{ display: isCollapsed ? 'none' : undefined }}
          onClick={() => handleToggleView(VIEW_TYPE.CONTOUR_N_SLOPE)}
        >
          <div
            className={`${styles.viewImageContainer} ${
              activeViews.has(VIEW_TYPE.CONTOUR_N_SLOPE) ? styles.selected : ''
            }`}
          >
            <img
              className={styles.view}
              src={ContourSlopeImg}
              alt="Contour & Slope View"
            />
            {activeViews.has(VIEW_TYPE.CONTOUR_N_SLOPE) && checkOverlay()}
          </div>
          <div className={styles.viewTitle}>Contour & Slope</div>
        </div>
        <div
          className={styles.spacer}
          style={{ display: isCollapsed ? 'none' : undefined }}
        />
        <div
          className={styles.viewContainer}
          style={{ display: isCollapsed ? 'none' : undefined }}
          onClick={() => handleToggleView(VIEW_TYPE.GEOLOGY)}
        >
          <div
            className={`${styles.viewImageContainer} ${
              activeViews.has(VIEW_TYPE.GEOLOGY) ? styles.selected : ''
            }`}
          >
            <img className={styles.view} src={GeologyImg} alt="Geology View" />
            {activeViews.has(VIEW_TYPE.GEOLOGY) && checkOverlay()}
          </div>
          <div className={styles.viewTitle}>Geology</div>
        </div>
        <div
          className={styles.spacer}
          style={{ display: isCollapsed ? 'none' : undefined }}
        />
        <div
          className={styles.viewContainer}
          style={{ display: isCollapsed ? 'none' : undefined }}
          onClick={() => handleToggleView(VIEW_TYPE.GEOMORPHOLOGY)}
        >
          <div
            className={`${styles.viewImageContainer} ${
              activeViews.has(VIEW_TYPE.GEOMORPHOLOGY) ? styles.selected : ''
            }`}
          >
            <img
              className={styles.view}
              src={GeomorphologyImg}
              alt="Geomorphology View"
            />
            {activeViews.has(VIEW_TYPE.GEOMORPHOLOGY) && checkOverlay()}
          </div>
          <div className={styles.viewTitle}>Geomorphology</div>
        </div>
        <button
          className={styles.toggle}
          style={{ display: isCollapsed ? 'none' : undefined }}
          onClick={(event) => {
            event.stopPropagation(); // Prevent outer div's onClick from being triggered
            setIsCollapsed(true);
          }}
        >
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default Views;
